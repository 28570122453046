.box-map-media {
		margin-top: 8px;
		background:url('../images/bg_info_map.png') no-repeat top;
		padding: 5px ;
		height: 195px;
}
#infobox {
	padding-bottom: 15px;
	text-align: left;
	h3 {
		margin: 0;
		padding: 15px 30px;
		color: white;
		font-family: 'PT Sans', sans-serif;
		font-weight: bold;
		@include font-size-map($fs-24);
		line-height: normal;
	}
	p.address {
		padding: 0 30px !important;
		margin: 0;
		font-family: 'PT Sans', sans-serif;
		font-weight: normal;
		color: white;
		@include font-size-map($fs-18);
		line-height: normal;
		text-align: left;
	}
}