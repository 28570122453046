.single-tab{
	.tab{
		background: #F5F5F5;
		text-align: center;
		padding: 10px;
		a{
			color: #c0a062;
			font-size: 24px;
		}
	}
}
