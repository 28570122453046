#sidebar {
  background-color: #e1e1e1;//#FFF;
  height: 100%; 
  position: fixed; 
  width: 3.25rem;
  z-index: 99;

  .bottom{
    text-align: center;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 90%;
    margin: 0 .3em 2em;
    ul{
      width: 3.25em;
      li{ 
        float: none;
        text-align: center;

        a{
          color: gray;
          &:hover, &:focus{ background: none; }
        }

        &:last-child{
          margin: 0 5px;
          background-color: $blueGG;
          a{
            color:#fff;
            padding: 10px!important;
          }    
        }

        .fa-2x{ font-size: 1.5em; }
      }
    }
  }

  nav{
    background-color: transparent;
    border: none;
    
      li{
        &:hover{ cursor: pointer; }
        float: none;
        text-align: center;
        padding-top: .5em; 
        padding-bottom: 100%;
        a{ padding: 15px 0; }
      }
  }
}