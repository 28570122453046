.row{
	&.carousels{
		padding: 0!important;
		height: 23em;

		.carousel-inner{
			img{ margin: 0 auto; }
		}
	}
}

.carousel{
	&.slide{
		height: 18em;

		.carousel-indicators{
			bottom: -4.25em;
			padding: 0;
			li{
				border: none;
				background-color: #c0a062;
				&.active{
					background-color: red;
				}
			}
		}

		.carousel-control{
			width: auto;
			padding: 0;
			top: 50%;
			opacity: 1;
			height: 2em;
			background-image: none;
		}
	}
}