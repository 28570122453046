.row{

	@media (min-width: 992px){
		#timeline{ /* tabbable for overview nav */
			border-right: thin solid grey;

			li{
				&:last-child{ a{ top:5px; } }
				&:not(:last-child){
					margin-bottom: 3em;
				}
				a{
					color: #555;
					padding: 0;
					text-align: right;
					right: 10px;
					bottom: 4px;
					&:hover, &:focus{ background: none; }

					.bullet{
						position: absolute;
						background: gray;
						border-radius: 50%;
					    right: 0;
					    top: 4px;
					    left: 11.7em;
					    width: 15px;
					    height: 15px;
					    z-index: 1;
						behavior: url(PIE.htc);
						@media (min-width: 1400px){
							left: 11.5em;
						}
					}
				}

				&.active{
					a{
						font-size: 24px;
						.bullet{
							background: $goldGG;
						    width: 25px;
						    height: 25px;
						    left: 7.6em;
						    @media (min-width: 1400px){
								left: 7.45em;
							}
						}
					}
				}
			}
		}
	}

	.nav-pills{
		border-right: thin solid red;
		min-height: 30em;
		li{
			&:first-child{ @media (min-width: 800px){ margin-top: 3em; } }

			a{ 
				@include futura-condensed-medium;
				color: $redGG;
				font-size: 22px;
				text-transform: uppercase;
				text-align: center;
				border-radius: 0;
				margin-bottom: 1em;

				&:hover{ background: none; }
			}

			&.active{
				a{
					color: white;
					background-color: $redGG;
				}
			}
		}
	}
}

.nav-tabs{
	margin-bottom: 1px!important;
	border: none;
	
	li a:hover{
		background: none;
		border: none;
		border-radius: 0;
	}

	li{

		&.active{
			a, a:hover, a:focus{
				background: none;
				border: none;
				border-radius: 0;
				color: $goldGG;
			}
		}
	}

	&.tab{
		font-size: 24px;
		background-color: rgba(198, 168, 112, 1);
		border: none;

		li{
			&:nth-child(1){ background-color: #a98c52; }
			&:nth-child(2){ background-color: #c0a062; }
			&:nth-child(3){ background-color: #c6a870; }
			&:nth-child(4){ background-color: #cbaf7b; }
			&:nth-child(5){ background-color: #d4ba8a; }
			&.active{ background-color: $gray; }
		}

		a{ 
			color: #fff;
			padding: .75em 0;
			border: none;
			@media (max-width: 768px){ padding: 15px 0; line-height: 1; }
		}

	}
}

.tab-content{ 
	background-color: #FFF;
	.row{ margin: 0; }

	h1{ color: $redGG; 
		font-size: 1.75em; 
		margin: 0;
	}
	h3{ color: $redGG; }

	p{ 
		color: $darkgray;
		text-align: justify;

		&.description{
			padding: 1.5em 3em;
			background-color: $gray;
			margin-bottom: 0;
			@media (max-width: 768px){ padding: 1em 1.2em; }
		}
		
	}

	.subtab{
		font-family: 'PTSansRegular', sans;
		font-weight: 300;
		background-color: $gray;

		li{ @media (max-width: 768px){ float: none!important; } }

		a{ color: $darkgray; }
		.active{
			a{
				border-bottom: $redborder!important;
				&.single{ @media (min-width: 481px){ max-width: 30%; margin: 0 auto; } }
			}
			span{
				color: $redGG;
				border-bottom: medium solid $redGG;
				padding: 0 0 .65em; 
				width: 15em;
			}
		}
	}

	.tab-pane{
		&.timeline{ padding: .5em 2em 1em; text-align: center; 
			p{ text-align: center; } 
		}
		&.active{
			.row{
				h1{ margin: 2em 2em 0;}
				.profile{
					width: 40%;
					@media (max-width: 768px){ width: 60%; margin: 0 auto; }
					@media (max-width: 480px){ width: 85%; margin-top: 1.5em; }
				}
				li{
					font-family: 'PTSansRegular', sans;
					color: $darkgray;
				}
			}
		}
	}

	@media (max-width: 768px){ .tab-pane .timeline{ padding: 0; text-align: center; } }
	
	.tab-content{ 
		&.sub{
			.row{
				.contents{ 
						font-family: 'PTSansRegular', sans;
			    		padding: 0 5em;

			    	@media (max-width: 768px){ padding: 0; }
			    	li{ color: $darkgray; }
					}
			}
		}

		.row{
			margin-left: 0; 
			margin-right: 0;
			@media (max-width: 768px){ padding: 0 1em; }
		}
	}
}

.tab-icon{
	padding: .5em;
	.nav-tabs{
		background: none;
		li{
			&:not(:first-child){ 
				border-left: thin solid $gray;
				max-height: 8.25em;
			}
    	&:not(.active){ .pindown{ opacity: 0; } }
    	&.active{
    		a{
    			color: $redGG;
    			&:hover{ color: $redGG; }
    			img{ filter: sepia() saturate(10000%) hue-rotate(30deg); }
    		}
    	}
	  }
	}

	.icon-text{
		margin: 0;
		img{
			padding: .5em 1em;
			@media (max-width: 768px){ display: none; }
		}
	}

	.tab-content{
		.tab-pane{
			text-align: center;
			p{ padding: 1em 1em 0; }
			img{ width: 95%; filter: grayscale(1) }
		}
	}
}