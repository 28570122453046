.mega-dropdown{
  &.open{
    a{
      &.dropdown-toggle, &:hover, &:focus{ background-color: transparent; }
    }
  }
}

#menuside{
  margin-top: 3%;
  margin-left: 43%;

  .close-mega-menu{
    color: #FFF;
    position: absolute;
    top: 5%;
    right: 10%;
  }
}

.mega-menu{
  margin-left: 5em;
  box-shadow: none;
  border: none;
  border-radius: 0;
  background-color: transparentize(rgba(0, 0, 0, 0.05), 0.1);
}

#menus, #menuside{ 
	min-height: 100%;
	width: 50%;

	.panel,
	.panel-heading,
	.panel-collapse{ 
		color: #FFF;
		background: none;
		border: none;
		box-shadow: none;
	}

	.panel-title{
		a{
			@include futura-condensed-medium;
			background-color: $redGG;
			padding: .3em 1.3em .3em;
			text-decoration: none;

			::hover{
				background-color: $redGG;
			}
		}

		a.collapsed{ background-color: transparent; }
	}

	.menu{
		a{ 
			color: #FFF;
			padding: .3em 3em .3em 1.3em;
			text-decoration: none!important;
		}
	} 

	.panel-body{
		border: none;
		padding: 0;

		.list-group{
			margin-bottom: 0;
			.list-group-item{
				background: none;
				border: none;
				border-radius: 0;
				color: #fff;
				margin-left: 2.5em;
				height: 35px;
				max-width: 80%;
				@media (max-width: 1024px){ max-width: 80%; }

				&.menu{ max-width: 35%; }

				&:hover{ border-bottom: 1px solid $redGG; }	
			}
		}
	}
}

#menus{
	margin-left: -4em;
	@media (min-width: 800px){ margin-top: 16%; }
}