.media {
  .tab-content {
    .active{ background-color: #f5f5f5; padding-bottom: 2em; }
    .panel{
      border-radius: 0;
      box-shadow: none;
      background-color: #efefef;
      padding: 1.5em;
    }

    .description{ text-align: center; }

    .form-contact {
      padding: 0 0 0 10%;
      @media (max-width: 768px){ padding: 0; }
      #id_to{
        background: none;
        -webkit-appearance: none;
        -moz-appearance: none;        
      }
      select{
        @include border-radius(0);
        background: $primary-border-color;
        border: none;
        margin-bottom: 1em;
        box-shadow: none;
        &:focus {outline: none}
        option{
          margin-bottom: .5em;

          &:hover{
            background: green;
          }
        }
      }
      input {
        @include border-radius(0);
        background: $primary-border-color;
        border: none;
        margin-bottom: 1em;
        box-shadow: none;
        &:focus {outline: none}
      }
      textarea {
        border: none;
        width: 100%;
        background: $primary-border-color;
        margin-bottom: 1em;
        height: 135px;
        &:focus {outline: none}
      }
      button {
        background: $primary-color;
        border: none;
        padding: 0.2em 2.2em;
        text-transform: uppercase;
        @include futura-condensed-medium;
        @include font-size-map($body-font-size);
        color: white;
        @media (max-width: 768px){ margin-bottom: 1em; }
      }
      label {
        text-transform: uppercase;
        @include font-size-map($body-font-size);
        @include futura-condensed-medium;
        color: $primary-color;
        line-height: 35px;
      }
    }

    .info-office {
      width: 100%;
      display: block;
      text-align: left;
      background: $primary-border-color;
      padding: 1em 2em;
      min-height: 280px;
      h4 {
        text-transform: uppercase;
      }
      p {
        text-align: left;
        @include font-size-map($fs-default);
        padding: 1em 0 0;
      }
    }
    
    .box-map-kontak {
      float:left;
      background: white;
      width: 100%;
      ul {
        margin-top: .5em;
        margin-bottom: 0;
        padding: 0;
        text-align: center;
        li{
          color: gray;
          text-transform: uppercase;
          text-align: center;
          display: inline-block;
          padding:1em 2.5em 0;
          @media (max-width: 1024px){ padding: 1em 1.5em 0; }
          cursor: pointer;
          &:not(:first-child){ border-left: 1px solid lightgray;   }
          &.current { color: $primary-color; cursor: default; }
          &:not(.current) i{ visibility: hidden; }

          &.custom{ position: relative; bottom: 1.2em; height: 4em; }
        }
      }
      #map-canvas {
        width: 100%;
        float:left;
        display: block;
      }

      #faspro{
        button{ 
          background: none;
          border: none;
          color: gray;
          text-transform: uppercase;
          font-size: 16px;
          outline: none;
          box-shadow: none;
          padding: 0 0 10px;
        }
        .dropdown-menu{ border: none; border-radius: 0;
          li{ padding: 10px; border: none; width: 150px;
            &:last-child{ border-top: thin solid lightgray; }
          }
        }
      }
    }
  }
}
