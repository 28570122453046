.hierarchy{
	color: #fff;
	text-align: center;
	margin-top: 2em;

	h4{ 
		font-weight: bold;
		margin-bottom: 1em;
	}

	.top{
		display: inline-flex;
		display: -webkit-inline-flex;
		display: -ms-inline-flexbox;
		margin-bottom:10px;
		.box{
			background-image: url('./../images/ketua.png');
			background-repeat: no-repeat;
    		background-size: cover;
			margin: 0 auto;
			width: 14em;
			@media (max-width: 768px){ width: 9em; }
			h5{
				&:last-child{ font-family: 'PTSansRegular', sans-serif }
			}
		}
	}

	.middle{
		.bullet{
			margin: 0 auto;
			background: $redGG;
			border-radius: 50%;
			width: 10px;
			height: 10px;
		}
		.bullet-empty{ 
			min-width:19.25em;
			@media (max-width: 768px){ min-width:11.25em; }
		}

		.line-v{
			height: 1.5em;
			margin: 0 auto;
			border-left: 2px solid $redGG;
			width: 1px;
			@media (max-width: 768px){ height: .5em; }
		}

		.line-h{
			border-top: thin solid $redGG;
			border-left: 2px solid $redGG;
			border-right: 2px solid $redGG;
			width: 20em;
			margin: 0 auto;
			@media (max-width: 768px){ width: 12em; }
		}

		.dots{
			display: inline-flex;
			display: -webkit-inline-flex;
			display: -ms-inline-flexbox;
		}
	}

	.bottom{
		display: inline-flex;
		display: -webkit-inline-flex;
		display: -ms-inline-flexbox;
		margin-bottom: 3em;
		.box{
			margin: 0 auto;
			width: 14em;
			background-repeat: no-repeat;
			background-size: cover;
			&:first-child{ background-image: url('./../images/anggota1.png'); }
			&:last-child{ background-image: url('./../images/anggota2.png'); }
			@media (max-width: 768px){ width: 9em; }

			h5{
				&:last-child{ font-family: 'PTSansRegular', sans-serif }
			}
		}

		.box-empty{
			width:6em;
			@media (max-width: 768px){ width: 2em; }
		}
	}
}