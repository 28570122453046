@import "modules/home";
@import "modules/pages";
@media (min-width: 992px){ @import "partials/topbar"; }
@media (max-width: 991px){ @import "mobile/topbar"; }
@import "partials/sidebar";
@media (min-width: 992px){ @import "partials/menu"; }
@media (max-width: 991px){ @import "mobile/menu"; }
@media (max-width: 800px){ @import "mobile/slider"; }
@import "partials/tab";
@import "partials/panel";
@import "partials/gallery";
@import "partials/share";
@import "partials/tree";
@import "partials/download";
@import "partials/modal";

@media (min-width: 992px){ @import "partials/footer"; }
@media (max-width: 991px){ @import "mobile/footer"; }
@media (max-width: 991px){ @import "mobile/scrolltab"; }
@media (max-width: 991px){ @import "mobile/singletab"; }
@media (max-width: 991px){ @import "mobile/table"; }
@media (max-width: 991px){ @import "mobile/paragraph"; }
@media (max-width: 991px){ @import "mobile/dropdown"; }
@media (max-width: 991px){ @import "mobile/select"; }

@import "partials/mixin";
@import "modules/media";
@import "vendors/_maps";
@import "./node_modules/jquery-fancybox/source/scss/jquery.fancybox.scss";
@import "modules/berita";
@import "modules/kontak";
@import "./node_modules/animate.css/animate";

//@import "partials/base";
// @import "partials/variables";
// @import "partials/typography";
