/************************
*       GENERAL         *
************************/

.logo-title{
  color: white;
  font-size: 3em;
  text-transform: uppercase;

  background: -webkit-radial-gradient(white, lightgrey, gray);
  background: -o-radial-gradient(white, lightgrey, gray);
  background: -moz-radial-gradient(white, lightgrey, gray);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.row{
  &.top{ 
    padding-top: 6em;
    @media (max-width: 768px){ padding-top: 5em; }
    .logo{ padding-top: 1em; }
    @media (max-width: 768px){ .logo{ img{max-width: 80%;}  } }
    p{ 
      line-height: 1.35em;
      padding: 1em 25% 2em;
      color: #FFF;
    }

    @media (max-width: 768px){
      p{ 
        padding: .5em 5% 1em;
      }      
    }
  }
}

.container{
  /************************
  *     PAGE :: KRETEK    *
  ************************/
  &.kretek{
    /* fix longtext on nav-tab mobile */
    .scroll-tab{
      ul{
        li{
          &:nth-child(2){
            a{
              @media (max-width: 640px){ padding: 10px; }
            }
          }
        }
      }
    }

    h3{ 
      @include futura-condensed-medium;
      text-align: center;
      padding-top: 1em;
      padding-bottom: 1em;
    }

    .tab-content{
      .contents{
        font-family: 'PTSansRegular', sans;
        @media (min-width: 801px){ padding: 3em 5em; }
        @media (max-width: 800px){ padding: 0 1em; }
      }
    }
  }

  /************************
  *     PAGE :: ABOUT     *
  ************************/
  &.about{
    /* fix longtext on nav-tab mobile */
    .scroll-tab{
      ul.tab{
        li{
          &:nth-child(2){
            a{
              @media (max-width: 640px){ padding: 6px 0 0; }
            }
          }
        }
      }
    }

    #overview{
      .title-top{ color: #c0a062; padding-top: 1em; }
      .row{
        p{ padding:.5em 4em 1em; }
        .timeline{
          p{ padding:.5em 3em 1em; 
            @media (max-width: 480px){ padding: 0; }
          }
        }
      }

      padding-bottom: 5em;
    }

    h3{ 
      @include futura-condensed-medium;
      text-align: center;
      padding-bottom: .5em;
    }
    
    #tatakelola{
      #tata-manajemen{
        .tab-content{ padding-left: 2.5em; padding-right: 2em; }
        .tab-pane{
          h3{ text-align: left; margin-top: 40px; margin-bottom: 20px; }
        }
        padding-bottom: 5em;
      }

      #tata-perusahaan{ padding-bottom: 5em;
        h3{ text-align: left }
        .row{ 
          padding-left: 4em;
          padding-right: 4em;
          p{ padding: .5em 0; }
        }
      }
    }

    #perusahaan{
      .row{
        @media (min-width: 992px){ padding: 1em 4em; }
        h3{ text-align: left; }
      }
    }
  }


  /************************
  *     PAGE :: INVESTOR  *
  ************************/
  &.investor{
    .nav{
      .five-tabs{ width: 20%; }
      li{
        &:last-child{ 
          a{ 
            @media (min-width: 800px){ padding: 1px 1px 1px 0; }
          }
          @-moz-document url-prefix() {
            a{ 
              @media (min-width: 800px){ padding: 1px 1px 1px 0; }
            }
          }
        }
      }
    }

    .tab-content{
      #keuangan{
        .row{
          .contents:nth-child(2){ background-color: #fbf8f8; }  
          padding: 0;
        }
      }
      #other{
        .row{
          .contents:nth-child(2){ background-color: #fbf8f8; }
          padding: 0;
        }
      }
      .row{
        padding: 0 2.5em .5em;
        @media (max-width: 768px){ padding: 0 1em; }
        @media (max-width: 640px){ padding: 0 .15em; }

        .selector{
          width: 10em;
          float:right;
          position: relative;
          @media (max-width: 768px){ margin-top: 4em; margin-bottom: -2em; }
          .dropdown{
            background: lightgray;
            padding: 0 1em;
            @media (max-width: 768px){ padding: 0 2em;  }
            font-family: 'PTSansRegular', sans;
            ul{ border: none; border-radius: 0; }
            .btn{ width: 100%; text-align: left;
              &:active{ box-shadow: none; }
              .arrow{
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                position: relative;
                float: right;
                top: 5px;
                left: 18px;
              }
            }

            &.open{
              .arrow{ border-bottom: 10px solid #f00; }
            }

            &:not(.open){
              .arrow{ border-top: 10px solid #f00; }
            }
          }
        }
      }
    }

    .contents{
      h3, h4{ color: $redGG; }
    }

    #keuangan{
      h3{ text-align: left; }
      .row{
        .contents{
          padding: 1em 4em 1em 2em;
          @media (max-width: 640px){ padding: 1em 1em 1em 1.5em; }
          p{
            padding: 1em;
            &:last-child{
              text-align: left!important;
              padding-right: 5em;
            }
          }

          .panel-group{
            &.laporan{
              @media (max-width: 768px){ margin-bottom: 0; }
            }
            .panel{
              border: none;
              box-shadow: none;
              padding: 0;
              border-radius: 0;
              .arrow{
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                position: relative;
                float: right;
                right: 10px;
                top: 10px;
              }
              .parent{
                padding: .6em;
                margin-bottom: 5px;
                cursor: pointer;
                background-color: lightgray;
              }
              .collapse, .collapsing{
                background-color: white;
                margin: 0;
                padding: 0;
                li{
                  padding: .25em;
                  font-size: 14px;
                  color: $darkgray;
                  text-align: right;
                  list-style: none;
                  input{
                    padding-left: 2px;
                  }
                  /*a{ background-color: $grey-color; padding: .5em 1.25em; }*/
                }
              }
              .collapsed{
                .arrow{ border-top: 12px solid #f00; border-bottom: none; }
              }

            }
          }

          .collapse-years{
            font-family: 'PTSansRegular', sans;
            font-size: 18px;
            margin: 0 5em 0 0;
            padding-top: .15em;
            padding-left: 1.2em;
            line-height: 1.5em;
            cursor: pointer;
            @media (max-width: 768px){ margin: 0; }
            .arrow{
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              position: relative;
              float: right;
              right: 10px;
              top: 10px;
            }

            &.collapsed{
              border: thin solid #eee;
              .arrow{ border-top: 10px solid #f00; }
            }

            &:not(.collapsed){
              .arrow{ border-bottom: 10px solid #f00; }
            }
          }
          .fin.collapse, .fin.collapsing{
            background-color: transparent;
            li{
              list-style: none;
              text-align: right;
              background-color: gray;
              padding: 5px;
              margin: 4px -4px;
            }
          }

          .collapse, .collapsing{
            cursor: pointer;
            background-color: $grey-color;
            margin: 0.15em 9em .5em 5em;
            text-align: center;
            padding: .25em;
            a{
              /*padding: .65em 1.2em .45em;*/
              font-family: 'PTSansRegular', sans;
              color: white;
              font-size: 12px;
              text-decoration: none;
              &:hover{ color: lightgrey; }
            }
          }
        }
      }
    }

    #other{
    .text2 {
        width: 100%;
        font-size: 16px;
    }
    .download2 {
        width: 100%;
        margin-bottom: 5px;
    }
      h3{ text-align: left; }
      .row{
        .contents{
          padding: 1em 4em 1em 2em;
          @media (max-width: 640px){ padding: 1em 1em 1em 1.5em; }
          p{
            padding: 1em;
            &:last-child{
              text-align: left!important;
              padding-right: 5em;
            }
          }

          .panel-group{
            &.laporan{
              @media (max-width: 768px){ margin-bottom: 0; }
            }
            .panel{
              border: none;
              box-shadow: none;
              padding: 0;
              border-radius: 0;
              background: none;
              .arrow{
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                position: relative;
                float: right;
                right: 10px;
                top: 10px;
              }
              .parent{
                padding: .6em;
                margin-bottom: 5px;
                cursor: pointer;
                background-color: lightgray;
              }
              .collapse, .collapsing{
                background-color: white;
                margin: 0;
                padding: 0;
                li{
                  padding: .25em;
                  font-size: 14px;
                  color: $darkgray;
                  text-align: right;
                  list-style: none;
                  input{
                    padding-left: 2px;
                  }
                  /*a{ background-color: $grey-color; padding: .5em 1.25em; }*/
                }
              }
              .collapsed{
                .arrow{ border-top: 12px solid #f00; border-bottom: none; }
              }

            }
          }

          .collapse-years{
            font-family: 'PTSansRegular', sans;
            font-size: 18px;
            margin: 0 5em 0 0;
            padding-top: .15em;
            padding-left: 1.2em;
            line-height: 1.5em;
            cursor: pointer;
            @media (max-width: 768px){ margin: 0; }
            .arrow{
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              position: relative;
              float: right;
              right: 10px;
              top: 10px;
            }

            &.collapsed{
              border: thin solid #eee;
              .arrow{ border-top: 10px solid #f00; }
            }

            &:not(.collapsed){
              .arrow{ border-bottom: 10px solid #f00; }
            }
          }
          .fin.collapse, .fin.collapsing{
            background-color: transparent;
            li{
              list-style: none;
              text-align: right;
              background-color: gray;
              padding: 5px;
              margin: 4px -4px;
            }
          }

          .collapse, .collapsing{
            cursor: pointer;
            background-color: $grey-color;
            margin: 0.15em 9em .5em 5em;
            text-align: center;
            padding: .25em;
            a{
              /*padding: .65em 1.2em .45em;*/
              font-family: 'PTSansRegular', sans;
              color: white;
              font-size: 12px;
              text-decoration: none;
              &:hover{ color: lightgrey; }
            }
          }
        }
      }
    }

    #yahoo-table-first, #yahoo-table-last{
      tr{
        td{
          padding-top: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid #ddd;
          &:first-child{ width: 51%; }
        }
      }
    }

    #saham{
      .results-change, .yahoo-values{
        .red{ color: red; }
        .green{ color: green; }
      }

      @media (min-width: 800px){ h3{ padding: 1em 0 .25em; } }
      .col-md-6{
        table{
          &:nth-child(2){
            td:first-child{
              max-width: 2.85em;
            }
          }
        }
      }
      table{
        tr:nth-child(1){
          td{ border-top: none; }
        }
      }

      #table-saham{
        table{
          tr:nth-child(1), tr:nth-child(2){
            td{ border-top: none; }
          }
        }

        #kuartal{
          @media (min-width: 800px){ h3{ margin-left: -.5em; } }
          .dropdown.kuartal-dropdown{
            font-family: 'PTSansRegular', sans;
            cursor: pointer;
            background-color: lightgray;
            padding: .1em .1em .1em 0.8em;
            margin: 1.5em 0 .5em 9em;
            font-size: 20px;
            @media (max-width: 768px){ 
              padding: 0;
              margin: 1em 0 0 2em;
            }

            .select-kuartal{
              @media (max-width: 768px){ padding-left: 15px; }
            }

            .dropdown-menu{
              font-size: 20px;
              border-radius: 0;
              border: none;
              min-width: 0;
              .active{
                a{
                  color: $darkgray;
                  background: none;
                }
              }
              a:hover{ background: none; }
            }

            .arrow{
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              position: relative;
              float: right;
              right: 10px;
              bottom: 20px;
            }

            &.open .arrow{ border-bottom: 10px solid #f00; }
            &:not(.open) .arrow{ border-top: 10px solid #f00; }
          }
        }
      }
    }
  }

  /************************
  *     PAGE :: RESULT    *
  ************************/
  &.search-result{
    .row{
      padding-top: 6em;
      .row{
        margin: 1em;
        padding: 1em;
        &:not(:last-child){ border-bottom: thin dotted lightgrey; }
        h3{
          margin: 0;
          a{
            color: black;
            text-decoration: none;
          }
        }
        p{ padding: 0; }
      }
      .head{
        padding: 1em;
        border-bottom: thin solid lightgray;
        h3{ color: $redGG; }
        .desc{
          span{ text-decoration: underline; }
        }
      }
      .meta-search{
        list-style: none;
        padding: .5em;
        li{
          padding-bottom: .25em;
          font-size: 14px;
          color: gray;
        }
      }
      .page-content{
        font-family: 'PTSansRegular', sans;
        background-color: white;

        .form-horizontal{
          padding: 1em;
          form{
            button, input{
              border-radius: 0;
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  /************************
  *     PAGE :: MEDIA     *
  ************************/
  &.media{
    #pressrelease{
      p{
       &.description{ text-align: center; }
      }
      .box-subscribe{
        margin: 0 30%;
        @media (max-width: 768px){ 
          margin: 0;
          padding: 0 10px;
          h3{
            font-size: 18px;
          }
        }

        form{
          width:100%;
          input {
            &#id_email{
              background: #eee;
              border: none;
            }
            height: 50px;
            width: 70%;
            float: left;
            padding: 0 15px;
            &:focus {
              outline: none;
            }
            &[type='submit']{
              width: 28%;
              border: none;
              margin-left: 2%;
              background: $second-color;
              color: #fff;
            }
          }
        }
      }
      .row::after{ margin-bottom: 3em; }
    }

    /* #galeri{ img{ width: 500px; height: 324px; } } */
  }

  &.detail{
    .press-detail{
      ul, ol{
        color: #555;
        font-family: 'PTSansRegular', sans;
      }
    }
  }

  /************************
  *     PAGE :: KARIR     *
  ************************/
  &.karir{
    .row{
      .tab-content{
        padding-top: 2em;
        padding-bottom: 3em;
        background-color: $gray;
        p{
          padding: .5em 3em;
          @media (max-width: 768px){ padding: 0 1em; }
        }

        a{ 
          font-size: 22px;
          padding: .25em 1.5em;
          @media (max-width: 768px){ padding: 5px; }
          color: $gray;
          text-decoration: none;
          background-color: #0077B5;
          margin: 0 1em;
          @media (max-width: 768px){ margin: 0; }

          &:last-child{
            background-color: $redGG;
          }
        }
      }
    }
  }

  /************************
  *     PAGE :: Brand     *
  ************************/
  &.brand{
    .row{
      .tab-content{
        .custom{ width:18%; display:inline-table;display:inline-block;
          span{ color: #b92025; font-family: 'PTSansRegular', sans; }
        }
      }
    }
  }
}