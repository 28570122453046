//Media Page
.fancybox-skin {background: black !important}
.media {
  .box-top{
    font-family: 'PT Sans', sans-serif;
    padding: 1em 0 0 2em;
    @media (max-width: 768px){ padding: 1em 0; }

    .indicator{
      float: right;
      a{
        margin-left: 2em;
        text-decoration: none;
        color: gray;
        &.gray{
          color: lightgray;
        }
      }
    }
  }
  .press-list, .news-list {
    float: left;
    display: block;
    width: 100%;
    padding-left: 2em;
    @media (max-width: 768px){
      padding: 0;
      float: none;
      h3{ font-size: 1em!important; }
    }

    .press-item {
      width:100%;
      padding-bottom: 25px;
      margin-bottom: 25px;
      border-bottom: 1px solid $gray;
      @media (max-width: 768px){ padding: 0; }
      h3 {
        text-transform: uppercase;
        @include font-size-map($h3-font-size);
        @include futura-condensed-medium;
      }

      .box-images {
        @extend .press-list;
        padding: 0;
        margin-bottom: 25px;
        position: relative;
        img{ margin: 0 auto; }
        .press-date {
          @extend .box-date-article;
          @media (max-width: 768px){
            padding: 2px;
            width: 50px;
            span{
              font-size: 14px;
            }
          }
        }
      }

      .more {
        a {
          background: $gray;
          padding: 10px 40px;
          color: $primary-color;
          border-radius: 0;
          border: none;
          font-family: 'PT Sans', sans-serif;
          &:hover{
            color: $black-color;
          }
        }
      }

      a {
        color: $primary-color;
        text-decoration: none;
        &:hover{
          color: black;
        }
      }

      p {
        margin-bottom: 2em;
        font-family: 'PT Sans', sans-serif;
        @include font-size-map($body-font-size);
      }
    }
  }

  .load-more {
    text-align: center;
    margin-top: 2em;
    @media (max-width: 768px){ margin: 1em 0 0; }
    a {
      background: lightgray;
      padding: 10px 3em;
      color: gray;
      border-radius: 0;
      border: none;
      font-family: 'PT Sans', sans-serif;
      @include font-size-map($h4-font-size);
    }
  }

  .selector{
    min-width: 30%;
    float:right;
    @media (max-width: 768px){ float: none; }
    .dropdown{
      background: lightgray;
      padding: 0 1em;
      .btn{
        width: 100%;
        text-align: left;
        &:active{ box-shadow: none; }
        .arrow{
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          position: absolute;
          top: 12px;
          right: 10px;
        }
      }

      &.open{
        .arrow{ border-bottom: 10px solid #f00; }
      }

      &:not(.open){
        .arrow{ border-top: 10px solid #f00; }
      }
    }
  }

  .counter{
    @media (min-width: 800px){
      position: absolute;
      bottom: 0;
    }
  }

  .box-ads{
    margin-bottom: 3em;
    img{
      @media (max-width: 768px){ padding-left: 35%; }
      @media (max-width: 480px){ padding-left: 20%; }
      @media (max-width: 375px){ padding-left: 16%; }
    }
  }

  .box-news {
    margin-bottom: 5em;
    float: left;
    display: block;
    width: 100%;
    @media (max-width: 768px){ margin-left: 2em; margin-bottom: 2em; }

    h4 {
      color: $bg-badge-press-color;
      margin: 0 0 0 -1em;
      padding:0 0 0 10px;
      text-transform: uppercase;
      @include font-size-map($h4-font-size);
      @include futura-condensed-medium;
    }

    ul {
      margin: 0;
      padding:0;
      list-style: none;
      li {
        width: 100%;
        float: left;
        display: block;
        margin-top:25px;
        padding-bottom: 20px;
        border-bottom: 1px solid $gray;
        @media (max-width: 768px){ margin-top:1em; }
        
        &:last-child{
          border: none;
        }


        .latest {
          display: block;
          width: 100%;
          text-decoration: none;

          div{
            padding: 0 6px 0 0;
            color: gray;


            img{ margin-top: 6px; }

            a{
              &.title{ 
                &:hover{ color: #999; text-decoration: none; }
                color: gray;
                text-transform: uppercase;
                font-weight: 900;
                @media (max-width: 768px){ font-size: 14px; padding-top: 2em; }
              }
            }
            .date{
              @media (max-width: 768px){ font-size: 12px; } 
            }
          }
        }
      }
    }
  }

  .box-archieve{
    padding: 0 90px 0 0;
    @media (max-width: 768px){ padding: 0 1em; }

    h4 {
      color: $bg-badge-press-color;
      margin:0;
      padding:0;
      text-transform: uppercase;
      @include font-size-map($h4-font-size);
      @include futura-condensed-medium;
    }

    .panel-group{
      margin-top: 2em;
      .item{
        margin-top: -1px;
        .panel-heading{
          border-radius: 0;
          font-family: 'PTSansRegular', sans;
          cursor: pointer;

          .arrow{
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            position: relative;
            float: right;
            right: 10px;
            top: 5px;
          }

          &.collapsed{ 
            border: thin solid lightgray;
            @media (max-width: 768px){ padding: 1em; }
            .arrow{ border-top: 12px solid #f00; }
          }

          &:not(.collapsed){ 
            background-color: lightgray;
            @media (max-width: 768px){ padding: 1em; }
            .arrow{ border-bottom: 12px solid #f00; }
          }
        }
        .panel-collapse{

          .panel-body{
            padding: 0;
            ul{
              margin: 0;
              li{
                color: #fff;
                list-style: none;
                background-color: gray;
                margin: 10px 0;
                padding-left: 10px;
                padding-bottom: 5px;
                a{
                  color: #fff;
                  text-decoration: none;
                  font-size: small;
                }
              }

              .items{
                padding: 0;
                list-style-type: circle;
                li{
                  background: none;
                  padding: 0;
                  a{ color: gray; }
                }
              }
            }
          }
        }
      }
    }
  }

  .box-bottom{
    padding: 2.3em 3em 0 0;
    @media (max-width: 768px){ padding: 1em 0 0 1em; }
    p{ text-align: left; line-height: 20px; }
  }

  .channel-subscribe{
    text-align: center;
    padding: 3em 3em 0;
    @media (max-width: 768px){ padding: 2em 1em 1em; }

    a{
      color: white;
      background-color: #c6222b;
      padding: 18px;
      font-size: 22px;
      text-decoration: none;
    }
  }

  .navigator{
    padding: 4em 1em 4em;
    @media (max-width: 768px){ padding: 1em 0; margin-bottom: 4em; }
    font-family: 'PTSansRegular', sans;
  }

  .featured-tvc {
    width: 100%;
    display: block;
    position: relative;
    height: auto;
    .caption {
      @media (min-width: 800px){
        bottom: 60px !important;
        h3 { max-width: 50% !important; }
      }
    }
  }

  #masonry {
    padding: 0;
    .grid-sizer { width: 50%; }
    .grid-item {
      width: 50%;
      height: auto;
      float: left;
      margin: 0;
    }
  }

  a.tvc-player {
    display: block;
    position: relative;
    height: auto;
    cursor: pointer;
    width: 100%;
    h3 {
      color: white;
    }
  }

  .caption {
    position: absolute;
    bottom: 30px;
    left: 10px;
    width: 95%;
    border-left: 5px solid $primary-color;

    h3 {
      float: left;
      @media (min-width: 800px){ max-width: 70%; }
      padding: 0 25px;
      @include futura-condensed-medium;
      @include font-size-map($h3-font-size);
      text-transform: uppercase;
      margin: 0;
    }
    img {
      display: inline-block;
      width: 65px;
    }
  }

  .playicon {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 30%;
    left: 0;
    right: 0;
    width: 15%;
  }

  #tvc-list{
    margin: 0 auto;
    .no-padding{ padding: 0;
      .thumb{ width: 100%; 
        @media (min-width: 800px){ height: 500px; }
      }
    }
    h3{ font-size: 1.2em; }
  }

  #map-canvas {
    margin: 0;
  	padding: 0;
  	height: 650px;
  	max-width: none;
    img { max-width: none !important; }
  }
}