.dropdown{
	

	&.general{ 
		text-align: center;
		margin-bottom: 10px;
		ul{
				left: 25%;
				@media (max-width: 480px){ left: 16%; }
				z-index: 1;
				border: none;
				border-radius: 0;
				li{
					font-size: 16px;
					margin: 2px;
					a{ padding: 10px 20px; }
					&.active{ a{ display: none; } }
					&:not(.active){ a{ background-color: lightgray; } }
				}
		}

		.btn{
			min-width: 5em;
			background-color: #b92025;
			border-radius: 0;
			color: white;
			padding: .5em;
			font-family: 'futura-condensed-medium', arial, sans;
	    font-weight: 300;
	    font-size: 22px;
	    text-transform: uppercase;
	    text-align: center;

	    span{
	    	margin-left: 10px;
	    	&.caret{
					border-top: 8px dashed;
					border-right: 8px solid transparent;
					border-left: 8px solid transparent;
				}
	    }
		}
		
	}
}