
#rups, #other, #pressrelease{
  .contents{
    p{ padding: 0; }
  }

  .panel{
    border: none;
    border-radius: 0;
    background-color: lightgray;
    &.last{ height: 12em; }
    h3{
      padding: 1em 1em 0 1em;
      @media (min-width: 800px){ min-height: 3.25em; }
    }
    p{
      padding: .5em 2em;
      height: 10em;
      @media (max-width: 768px){ height: 9em; text-align: left; }
    }
  }
}