.row{
	.dropup{
		text-align: center;
  	margin: 5em 3em 0;
    border-top: thin solid #D4D4D4;

  		.btn{
  			padding: 0;
  			border: none;
  		}
      
      @media (min-width: 1201px) { .share-menu{ left: 47%; } }
      @media (max-width: 1200px) { .share-menu{ left: 46.25%; } }

  		.share-menu{
        opacity: 0;

        /* -moz-transition:    all 1000ms ease;
        -webkit-transition: all 1000ms ease;
        -o-transition:      all 1000ms ease;
        -ms-transition:     all 1000ms ease;
        transition:         all 1000ms ease; */

    
  			background-color: #ebebeb;
  			margin-bottom: 0;
  			/* left: 47%; */
  			min-width: 4.25em;
  			border: none;
  			border-radius: 0;
  			box-shadow: none;
  			li{
  				padding: 0.5em .8em;
  			}

  			a{
  				background-color: transparent;
  				border: 2px #1b1b1b solid;
			    width: 2.5em;
			    height: 2.5em;
			    text-align: center;
			    padding: 7px;
			    border-radius: 50%;

			    i{
			    	color: #1b1b1b;
			    }
  			}
  		}
  	}
}