.container{
	&.about{
		#overview{
			.row{
				p{
					padding: 0;
				}
			}
		}
		#tatakelola{
			#tata-perusahaan{
				.row{
					  padding: 0 1em;
					p{
						padding: 0;
					}
				}
			}
		}
	}
}