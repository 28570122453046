a{
	&.thumbnail, &:hover, &:active{ 
		border: none;
		border-radius: 0;
		outline: none;
	}
}

#gudanggaramfamily{
	.subtab{
		.active{
			a{ color: $redGG; }	
			span{
				left: 0;
				width: auto;
			}
		}
		li{
			a{ padding: 0 0 10px; }			
		}
	}

	p{
		&:not(.desc){
			padding: 2em;
			text-align: justify;
		}
		&.desc{
			font-size: 14px;
			line-height: 16px;
			padding: 0 10px;
			text-align: center;
		}
	}
	.row{
		div{
	 		padding: 0;
	 		margin: 0;
	 	}
	}
}
