/* general */
.row{
  hr{ border-top: 1px solid #b5b5b5; }

  div{
    padding-left: 0;
    padding-right: 0;
  }

  .tab-content{
    .row{
      p{
        padding: 0;
      }
    }
  }

}

#topbar{ 
  background-color: black;
  height: 4.5em;
  padding-left: 0;
  @media (max-width: 320px){ padding: 0!important; }

  &.menu-mobile{
    margin-left: 3.5em;
  }

  .navbar-header{ 
    margin: 0;
    
    .navbar-brand{
      height: 4em;
      background-color: $redGG;
      position: relative;
      left: -15px;
      margin-left: 0;
      padding: 15px 0;
      @media (max-width: 320px){ width: 60%; }
      .logo-mobile{
        position: relative;
        top: -15px;
        padding: 0;
        @media (max-width: 320px){ top: -7; }
      }
    }

    .changer{
      display: inline-flex;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      float: right;
      color: #fff;
      padding: 20px 0 0;
      .left{
        @media (max-width: 480px){ position: absolute; right: 2.75em; }
        .top{ 
          font-size: 14px;
          @media (max-width: 320px){ font-size: 11px; }
        }
        .bottom{
          font-size: 10px;
          @media (max-width: 320px){ font-size: 9px; }
          i{
            &.fa-sort-desc{ padding-left: 1em; color: red; position: relative; bottom: 3px; }
            &.fa-sort-asc{ padding-left: 1em; color: greenyellow; position: relative; top: 3px; }
          }
        }
      }
      .right{
        padding: 2px 8px;
      }
      .lang{
        padding-top: 5px;
        a{
          color: #fff;
          background: $blueGG;
          padding: 4px 6px;
        }
      }
    }
  }
}