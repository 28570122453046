#topbar{ 
  background-color: black;
  margin-left: 3.25rem;
  padding-left: 0;

  .navbar-header{ 
    float: none;
    height: 100%; 
    .navbar-brand{
      padding: 0;
      display: inline-table;
      vertical-align: middle;
      background: $redGG;
      height: 4.45em;
      font-size: 16px;
      img{
        max-width: 100%;
      }
    }

    .hut{
      position:relative;
      top: 8px;
      left: 10px;
      @media (max-width: 1024px){ width: 28px; top: 23px; }
    }

    .extra{
      .extra-nav{
        ul{
          li{
            a{
              &:focus,&:hover{ background: none; }
              padding: 25px 5px 0;
            }
          }
        }
      }
    }

    .changer{
      display: inline-flex;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      color: #fff;
      padding: 20px 0 0;
      .left{
        .top{ font-size: 18px; }
        .bottom{
          font-size: 12px;
          position: relative;
          bottom: 5px;
          i{
            &.fa-sort-desc{ padding-left: 1em; color: red; position: relative; bottom: 3px; }
            &.fa-sort-asc{ padding-left: 1em; color: greenyellow; position: relative; top: 3px; }
          }
        }
      }
      .right{
        padding: 2px 20px;
      }
    }
  }

  form{
    padding-left: 0;
    .input-group{
      margin-top: .5em;
      span{ background: none; border: none; cursor: pointer; }
    }
  }

  input{ 
    color: #FFF;
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin-top: 7px;
    padding-left: 0;
    height: 35px;

    &:hover{ background-color: #fff; padding-left: 15px; color: #000; }

    &::-moz-placeholder { color: #FFF; opacity: 1; }
    &:-ms-input-placeholder { color: #FFF; }
  }
}