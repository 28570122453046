.modal{

  &.fade-scale {
    transform: scale(0);
    opacity: 0;
    -webkit-transition: all .25s linear;
    -o-transition: all .25s linear;
    transition: all .25s linear;
  }

  &.fade-scale.in {
    opacity: 1;
    transform: scale(1);
  }

  &.wide{
    .modal-dialog {
      width: 80%;
      height: auto;
      margin: 2% 12.5%;
      @media (max-width: 768px){ width: 90%; margin: 20% auto 0;
        button{ position: relative; bottom: 15px; right: 5px; }
      }
      .modal-content{
        text-align: center;
        background-color: $gray;
        border-radius: 0;
        border: none;
        h3{ 
          font-family: 'PTSansRegular', sans;
          font-size: 22px;
          text-transform: uppercase;
          padding-top: 1.5em;
        }
        img{ max-width: 65%; }
        p{
          margin: 0;
          padding: .5em!important;
          text-align: center!important;
          color: $darkgray;
        }
      }
    }
  }
}