.detail {
  .featured-image {
    width: 100%;
    position: relative;
    background-color: white;
    img{ margin: 0 auto; }
    .featured-date {
      @extend .box-date-article;
      /* left: 45px; */
      /* bottom: 0; */
      /* top: auto; */
    }
  }
  .tab-content {
    background: white;
    .press-detail {
      float: left;
      display: block;
      width: 100%;
      padding: 50px 0 0 45px;
      @media (max-width: 800px){ padding: 20px 20px 0; }
      .breadcrumb {
        @extend .gg-breadcrumb;
      }
      .press-tools {
        width: 100%;
        position: relative;
        display: block;
        border-bottom: 1px dotted lightgray;
        padding-bottom: 0.5em;
        float: left;
        .act-back {
          width: 30%;
          float: left;
          display: inline-block;
          a {
            color: $primary-color;
            text-transform: uppercase;
            /* @include futura-condensed-medium; */
            /* @include font-size-map($body-font-size); */
            span {
              margin-right: 1em;
            }
            &:hover {
              text-decoration: none;
              color: black;
            }
          }
        }
        .act-others {
          float: right;
          a {
            color: lightgray;
            text-decoration: none;
            font-family: 'PT Sans', sans-serif;
            margin-right: 0.5em;
            cursor: pointer;
            &:hover {
              color: $darkgray;
            }
            &.s-small {
                @include font-size-map($fs-default);
            }
            &.s-medium {
                @include font-size-map($body-font-size);
            }
            &.s-large {
                @include font-size-map($fs-18);
            }
            i {
                @include font-size-map($fs-18);
                padding: 0 0.3em 0 0.7em;
                border-left: 1px solid lightgray;
            }
          }
        }
      }
      .txt-detail {
        width: 100%;
        display: block;
        position: relative;
        float: left;
        text-align: left;
        padding: 3em 0 0;
        @media (max-width: 480px){ padding: 10px 0 0; }
        h2 {
          color: $primary-color;
          margin: 0 0 1.5em;

        }
        p {
          padding:0;
          text-align: justify;
          font-family: 'PT Sans', sans-serif;
          line-height: 1.8em;
          margin: 0 0 1.5em;
        }
      }
      .article-tools {
        width: 100%;
        display: block;
        float: left;
        margin: 1em 0 3em;
        padding: 0.5em 0;
        border-bottom: 1px solid $grey-btn-color;
        position: relative;
        a {
          &.nav-article {
            background: $grey-btn-color;
            padding: 0.5em 0.7em;
            @include font-size-map($fs-20);
            display: inline-block;
            color: $primary-color;
            &.next-article {
              float:right;
            }
            &:hover {
              color: black;
            }
          }
        }
        .dropup {
          margin: 0;
          width: auto;
          border: none;
          display: inline-block;
          position: absolute;
          bottom:0;
          left: 45%;
          .share-menu {
            left: 1%;
          }
        }
      }
    }
    .head-content {
      padding: 1.5em 0 2em;
      h3 {
        margin:0;
        padding:0;
        line-height: normal;
        color: $second-color;
        text-align: center;
      }
    }
    .box-news-archive {
      .panel-group {
        margin-top: 2em;
        .panel-default {
          border: none;
          box-shadow: none;
          .panel-heading {
            @include border-radius(0);
            h4 {
              font-family: 'PT Sans', sans-serif;
              @include font-size-map($body-font-size);
              letter-spacing: 0.025em;
            }
            a {
              display: block;
              width: 100%;
              text-decoration: none;
            }
            span, i {
              float: right;
              color: $redGG;
            }
          }
          .panel-body {
            padding: 1em 0;
            border: none;
          }
        }
      }
      ul {
        li {
          margin: 0;
          padding-bottom: 0;
          border: none;
          &:last-child {
            a {
              margin-bottom: 0;
            }
          }
          a {
            float: right;
            width: 90%;
            display: block;
            background: $darkgray;
            margin-bottom: 1em;
            color: white;
            padding: 0.5em 1em;
            font-family: 'PT Sans', sans-serif;
            @include font-size-map($fs-small);
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .box-news {
    @media (min-width: 800px){ margin-top: 6em; }
    float: left;
    display: block;
    width: 100%;
    @media (max-width: 768px){ padding-left: 2em; }

    h4 {
      color: $bg-badge-press-color;
      margin:0;
      padding:0;
      text-transform: uppercase;
      @include font-size-map($h4-font-size);
      @include futura-condensed-medium;
    }

    ul {
      margin: 0;
      padding:0;
      list-style: none;
      li {
        width: 100%;
        float: left;
        display: block;
        margin-top:25px;
        padding-bottom: 20px;
        border-bottom: 1px solid $gray;
        
        &:last-child{
          border: none;
        }


        a {
          display: block;
          width: 100%;
          text-decoration: none;

          div{
            padding: 0 6px 0 0;
            color: gray;

            &:hover{ color: black; }

            img{ margin-top: 6px; }

            .title{ 
              font-weight: 600;
              font-family: 'PT Sans', sans-serif;
            }
          }
        }
      }
    }
  }
}
