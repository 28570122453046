.row{
  .download{
    /* display: inline-flex; */
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    background: #CCC;
    margin-bottom: 1em;

    .text{
      padding: 1em;
      text-align: left; 
      width: 20em;
      @media (max-width: 768px){ width: 17em; }
      font-family: 'PTSansRegular', sans;
      font-weight: bold;
      color: gray;

      img{
        width: 1.75em;
        margin-right: 10px;
      }
    }
    
    a{
      float: right; 
      border-left: thin solid gray; 
      padding: .5em;
    }
  }

  .doc{
    margin: 3em;
    border-bottom: thin solid lightgray;
    padding-bottom: 3em;
    @media (max-width: 768px){
      margin: 1em;
      padding-bottom: 1em;
    }
  }
}