#menu{
	&.menu-mobile{
		background: lightgray;
	    width: 3.5em;
	    left: 0;
	    top: 0;
	    padding: 15px 0;
	    @media (max-width: 480px){ padding: 10px!important; }
	    margin: 0;
	    height: 72px;
	    border-radius: 0!important;

	    .mega-dropdown{
	    	.dropdown-toggle{
	    		position: relative;
			    left: 12px;
			    top: 4px;
			    @media (max-width: 480px){ left: 16px; }
	    	}

	    	&.open{
			    /* a{
			      &.dropdown-toggle, &:hover, &:focus{ background-color: transparent; }
			    } */
		  	}
	    }
	}

	.boxes-menu{
      position: absolute;
      top: 30em;
      left: -4em;
      width: 100%;
      ul{
      	position: absolute;
      	left: -2.7em;
      	bottom: 1em;
        display: none;
        list-style-type: none;
        color: white;
        li{
        	a{
        		color: white;
        	}
        }
      }
      .box{
        padding: .45em;
        background: rgba(0, 0, 0, 0.5);
        a{ color: white; }
      }
    }
}

#menuside{
  margin-top: 3%;
  margin-left: 3%;

  .close-mega-menu{
    color: #FFF;
    position: absolute;
    top: 2em;
    right: 25%;
  }
}

.mega-menu{
  margin-left: 5em;
  box-shadow: none;
  border: none;
  border-radius: 0;
  background-color: transparentize(rgba(0, 0, 0, 0.05), 0.1);
}

#menus, #menuside{
	min-height: 100%;
	margin: 3em 0 0 3%;

	.panel,
	.panel-heading,
	.panel-collapse{ 
		color: #FFF;
		background: none;
		border: none;
		box-shadow: none;
	}

	.panel-title{
		a{
			@include futura-condensed-medium;
			background-color: $redGG;
			padding: .3em 1.3em .3em;
			text-decoration: none;

			::hover{
				background-color: $redGG;
			}
		}

		a.collapsed{ background-color: transparent; }
	}

	.menu{
		a{ 
			color: #FFF;
			padding: .3em 3em .3em 1.3em;
			text-decoration: none!important;
		}
	}

	.panel-body{
		border: none;
		padding: 0;

		.list-group{
			margin-bottom: 0;
			.list-group-item{
				background: none;
				border: none;
				border-radius: 0;
				color: #fff;
				margin-left: 2.5em;
				height: 35px;
				max-width: 80%;

				&.menu{ max-width: 60%; }

				&:hover{ border-bottom: 1px solid $redGG; }	
			}
		}
	}
}

#menus{
	@media (max-width: 768px){ margin: 15% 0 0 30%!important; }
	@media (max-width: 480px){ margin-top: 20%!important; }
	@media (max-width: 320px){ margin: 25% 0 0 15%!important; }
}

#input-search-mobile-menu{
	display: none;
    position: absolute;
    margin: 0 auto;
    top: 5em;
    z-index: 9;

    input{
      width: 20em;
      height: 2.25em;
      background-color: white;
      border: none;
      border-radius: 50px;
      text-indent: 10px;
      outline: none;
    }
}