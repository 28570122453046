@import "partials/base";

#topbar{
  .extra{
    @media (max-width: 1024px){ left: -15px; }
    @media (max-width: 1280px) and (min-width: 1025px){ left: -1em; }
    @media (max-width: 1366px) and (min-width: 1281px){ left: -2em; }
    @media (min-width: 1440px){ left: -2em; }
    @media (min-width: 1800px){ left: -8em; }
  }
}

#home{
  overflow: hidden;
  background-color: #000;
  background-size: cover;
  @media (max-width: 768px){ background-position: center; }
  background-repeat: no-repeat; 
  height: 100%;

  //filter: blur(3px);
  transition: all 2s ease-in-out;
  -webkit-transition: all 2s ease-in-out;
  -moz-transition: all 2s ease-in-out;
  -o-transition: all 2s ease-in-out;

  .row{ 
  	background-color: rgba(0, 0, 0, .15);
  	height: 100%;

    .boxes{
      position: absolute;
      width: 5em;
      left: 2%;
      bottom: 15%;
      ul{
        display: none;
        list-style-type: none;
        color: white;
        padding-left: 0;
        padding-right: 50%;
      }
      .box{
        padding: .45em;
        background: rgba(0, 0, 0, 0.5);
        a{ color: white; }
      }
    }

    .sentences{
      width: 30%;
      float: left;
      position: absolute;
      bottom: 1em;
      left: 6px;
      color: white;
      text-align: left;
      border-left: thin solid white;
      padding-left: 5px;
      background-color: rgba(0, 0, 0, 0.35);
      font-size: 12px;
    }
  }

  #input-search-mobile{
    display: none;
    position: absolute;
    margin: 0 auto;
    top: 6em;
    left: 0;
    right: 0;
    text-align: center;

    input{
      width: 20em;
      height: 2.25em;
      background-color: white;
      border: none;
      border-radius: 50px;
      text-indent: 10px;
      outline: none;
    }
  }

  .scrollstart{
  	position: absolute;
  	bottom: 16px;
  	left: 0;
    right: 0;
    text-align: center;

  	.button{ color: rgba(255, 255, 255, .65); margin-bottom: .5rem; }
  	a{ 
  		background-color: $redGG;
  		padding: .25em .5em 1.25em;
  	}

  	i{ background-color: yellow; }
  	img{ position: relative; top: 6px; }
  }

  .col-md-5{ min-height: 100%; } 

  .quotes{
  	color: #FFF;
    position: absolute;
    left: 5em;
    bottom: 2em;

  	p{
      line-height: 1.25em;
  		width: 8em;
  		font-size: 1.5em;
  		margin-bottom: 0;
  		padding: 0;
      @include futura-condensed-medium;
  	}
  }

  #image-description{
    font-family: 'PTSansRegular', sans;
    color: #FFF;
    position: absolute;
    bottom: 8%;
    right: 10%;
    font-size: 14px;
  }
}