/* Small devices (tablets, 768px and up :: @screen-sm-min) */
@media (min-width: 800px) { .container{ padding-left:7%; } }
/* Medium devices (desktops, 992px and up :: @screen-md-min) */
@media (min-width: 992px) { .container{ padding-left:7%; } }
/* Large devices (large desktops, 1200px and up :: @screen-lg-min) */
@media (min-width: 1200px) { .container{ padding-left:5%; } }

@mixin font-face($font-family, $file, $font-weight, $font-style) {
  @font-face {
    $file-path: "../fonts/" + $file;
    font-family: $font-family;
    src: url('#{$file-path}.eot'); /* IE9 Compat Modes */
    src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$file-path}.woff') format('woff'), /* Modern Browsers */
         url('#{$file-path}.ttf') format('truetype');
    font-weight: $font-weight;
    font-style: $font-style;
    text-rendering: optimizeLegibility;
  }
 }

@mixin futura-condensed-medium {
  font-family: 'futura-condensed-medium', arial, sans;
  font-weight: 300;
}


@font-face {
  font-family: 'PTSansRegular';
  src: url('PTS55F_W.eot');
  src:
       url('../fonts/PTS55F_W.eot?#iefix') format('embedded-opentype'),
       url('../fonts/PTS55F_W.woff') format('woff'),
       url('../fonts/PTS55F_W.ttf') format('truetype'),
       url('../fonts/PTS55F_W.svg#PTSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}


@mixin PTSans {
  @font-face {
    font-family: 'PTSansRegular';
    src: url('PTS55F_W.eot');
    src:
         url('../fonts/PTS55F_W.eot?#iefix') format('embedded-opentype'),
         url('../fonts/PTS55F_W.woff') format('woff'),
         url('../fonts/PTS55F_W.ttf') format('truetype'),
         url('../fonts/PTS55F_W.svg#PTSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}

/********************
*   GENERAL VARS    *
********************/
$redGG : #b92025!default;
$blueGG : #1a1448!default;
$goldGG : #c0a062!default;
$whiteborder : medium solid #FFF;
$blueborder : thin solid $blueGG;
$redborder : thin solid $redGG;
$gray: #F5F5F5!default;
$darkgray: #555!default;

$primary-color: #c6222c;
$second-color: #c0a062;
$grey-color: #555555;
$black-color: #000000;
$grey-btn-color: #e7e7e7;
$bg-badge-press-color: #1b1448;
$primary-border-color: #efefef;

// breakpoint variables
$lg: 1170px;
$md: 1024px;
$sm: 640px;
$xs: 480px;

// body font size
$browser-context: 14;

// typography scale
$body-font-size: (null: 14, $md: 15, $sm: 14, $xs: 13);
$h1-font-size: (null: 46);
$h2-font-size: (null: 36);
$h3-font-size: (null: 22);
$h4-font-size: (null: 18);
$fs-18: (null: 18);
$fs-20: (null: 20);
$fs-24: (null: 24);
$fs-default: (null: 14);
$fs-small: (null: 10);
$image-path: '../node_modules/jquery-fancybox/source/img/' !default;

html, body, main{
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  @include futura-condensed-medium;
  font-size: 16px;
}

.no-padding{ padding: 0; }

.container.top{ 
  width: 100%;
}

#parallax{
  background-color: #000;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  width: 100%;
  padding: 0;

  /* filter: blur(3px); */
  /* transition: all 2s ease-in-out; */
    /* -webkit-transition: all 2s ease-in-out;
    -moz-transition: all 2s ease-in-out;
    -o-transition: all 2s ease-in-out; */
}

.overlay{
  background-color: rgba(0, 0, 0, 0.25);
  &.swipe{
    position: absolute;
    z-index: 99;
    color: white;
    width: 100%;
    height: 54px;
    padding: 8px;
    text-align: center;
    .middle{ font-size: 24px; }
    .prev{ float: left; }
    .next{ float: right; }
  }
}

nav{
  #menu{
    position: absolute;
    left:10px;
    top:22px;
    background: transparent;
    border: none;
    z-index: 999;
  }
}

section{
  p{ 
    font-family: 'PTSansRegular', sans;
    line-height: 1.75em;
  }
  h4{ color: $redGG; font-family: 'PTSansRegular', sans; }
  h6{ color: gray; }
  ol{ padding: .5em 8em; line-height: 2em;
    @media (max-width: 768px) { padding: 0 1em; }
  }
}

table{
  font-family: 'PTSansRegular', sans;
}

.white{ color: #FFF; }
.red{ color: $redGG; }
.gold{ color: $goldGG; }
.img-center { margin:0 auto; }

.navbar-fixed-top{
  z-index: 999;
}

.line-divider{
  border-bottom: thin solid #D4D4D4;
  margin: 1.2em 3em;
}

.scroller{
  margin-top: 1em;
  text-align: center;
  cursor: pointer;
  
  a{
    color: gray;
    text-decoration: none;

    img{
      height: 1em;
      width: 3em;
    }
  }
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #f00;
  position: relative;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #f00;
  position: relative;
}

.box-date-article {
  position: absolute;
  left: 0;
  top: 0;
  width: 70px;
  @media (max-width: 480px) { padding: 5px; }
  text-align: center;
  padding: 5px 0;
  line-height: normal;
  background: $bg-badge-press-color;
  span {
    @include futura-condensed-medium;
    font-size: 18px;
    @media (max-width: 480px) { font-size: 14px; }
    text-transform: uppercase;
    width: 100%;
    display: block;
    color:$second-color;
  }
  strong {
    @extend span;
    font-size: 30px;
    line-height: normal;
    margin: 5px 0;
  }
}
 
 .gg-breadcrumb {
   background: none;
   padding: 0;
   line-height: normal;
   li {
     font-family: 'PT Sans', sans-serif;
     line-height: normal;
     a {
       color: $grey-color;
     }
     &.active {
       color: $primary-color;
     }
   }
 }