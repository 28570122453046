// Font Face
@mixin font-face($font-family, $file, $font-weight, $font-style) {
  @font-face {
  	$file-path: "../fonts/" + $file;
    font-family: $font-family;
    src: url('#{$file-path}.eot'); /* IE9 Compat Modes */
    src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$file-path}.woff') format('woff'), /* Modern Browsers */
         url('#{$file-path}.ttf') format('truetype');
    font-weight: $font-weight;
    font-style: $font-style;
    text-rendering: optimizeLegibility;
  }
 }

@include font-face('futura-condensed-medium', 'futura-condensed-medium', 'normal', 'normal');

@mixin futura-condensed-medium {
  font-family: 'futura-condensed-medium', arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}

// unitless function to convert px to em
@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @if (unitless($context)) {
    $context: $context * 1px;
  }
  @return $pixels / $context * 1em;
}

// breakpoint mixin
@mixin breakpoint($mq01: 0, $mq2: false, $maxmin: max-width) {
    @if $mq2 == false {
        @media ($maxmin: $mq01) {
            @content;
        }
    }
    @else {
        @media (min-width: $mq01) and (max-width: $mq2) {
            @content;
        }
    }
}

// responsive font size mixin
@mixin font-size-map($font-size-map) {
    @each $breakpoint, $font-size in $font-size-map {
        @if $breakpoint == null {
            font-size: em($font-size);
        } @else {
            @include breakpoint($breakpoint) {
                font-size: em($font-size);
            }
        }
    }
}

@mixin box-shadow($top, $left, $blur, $right, $color) {
  -webkit-box-shadow:$top $left $blur $right $color;
  -moz-box-shadow:$top $left $blur $right $color;
  box-shadow:$top $left $blur $right $color;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  -moz-border-radius: $radius;
}
