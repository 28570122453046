#footer{
  padding: 1em;
  background: black;
  font-family: 'PTSansRegular', sans;
  font-size: 14px;
  small{ font-size: 65%; }
}

.container.divider{
	display: none;
	/* padding: 45px; */
}