.styled-select {
	margin-left: auto;
	margin-right: auto;
	/* margin-bottom: 10px; */

	background: #b92025;
	height: 50px;
	overflow: hidden;
	width: 280px;
	color: white;

	select{
		background: transparent;
		border: none;
		font-size: 22px;
		padding: 0 10px;
		height: 50px;
		width: 280px;
		text-transform: uppercase;

		option{ color: gray; }
	}
}