.scroll-tab{
  overflow-x: auto;
  overflow-y: hidden;
  overflow: -moz-scrollbars-horizontal;

  ul{
    li{
      list-style: none;
      float: left;
      text-align: center;
      width: 240px;
    }
  }
}

.childtab{
  a{
    font-family: 'PTSansRegular', sans;
    color: #555;
  }

  .active{
    a{
      border-bottom: thin solid #b92025!important;
      span{

        color: #b92025;
        border-bottom: medium solid #b92025;
        padding: 0 0 .65em;
        width: 15em;
      }
    } 
  }
}

.scroller{ display: none; }

#timeline{
  overflow: auto;
  overflow-y: hidden;
  white-space: nowrap;
  li{
    display: inline-block;
    vertical-align: top;
    border-top: thin solid gray;
    margin-top: 5px;
    margin-left: -4px;

    &:first-child{
      &:before{
        content: "";
        width: 50%;
        height: 1px;
        background-color:#fff;
        position: absolute;
        top:-1px;
      }
    }

    &:last-child{
      &:after{
        content: "";
        width: 50%;
        height: 1px;
        background-color:#fff;
        position: absolute;
        right: 0;
        top:-1px;
      }
    }


    a{
      color: black;
      .bullet{
        position: absolute;
        background: gray;
        border-radius: 50%;
        left: 29px;
        bottom: 37px;
        width: 10px;
        height: 10px;
        z-index: 1;
      }
    }
    &.active{
      a{
        font-size: 18px;
        &:hover{ background: none; }
        .bullet{
          background: $goldGG;
          bottom: 38px;
          width: 13px;
          height: 13px;
        }
      }
    }
  }
}

#kretek{
  .scroll-tab{
    .childtab{
      li{ width: auto; }
    }
  }
}