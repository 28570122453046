#footer{
  padding: 1.35em 0 1.36em;
  background: black;
  font-family: 'PTSansRegular', sans;

  small{ font-size: 75%; }
}

.container.divider{
	padding: 45px;
}